<template>

    <div id="nav">

        <font-awesome-icon v-if="!expandedProfile" icon="fa-solid fa-list" id="expand" size="lg" @click="$emit(`ToggleExpand`)" />
        <a href="/" id="navTitle">Fandle <span id="artist">Studio</span></a>

    </div>

</template>

<script>

    export default {
        props: [`expanded`, `expandedProfile`],
        methods: {
            OpenProfile() {
                if (this.$store.state.Account) {
                    this.$emit(`ToggleProfileExpand`)
                } else {
                    this.$router.push(`/login`)
                }
            }
        }
    }
  
</script>

<style scoped>

    #navTitle {
        width: 60% !important;
        min-width: 270px !important;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        white-space: none;
        display: inline-block;
    }

    a {
        margin: auto;
        font-size: 24px;
        font-weight: bold;
    }
    
    #artist {
        color: #8356f3;
    }

    #nav {
        width: 100%;
        height: 70px;
        overflow: hidden;
        padding: 20px 0px;
    }

    #expand {
        position: absolute;
        top: 15px;
        left: 15px;
        background-color: #733CFF;
        border-radius: 6px;
        padding: 10px;
    }

    #expand:hover {
        cursor: pointer;
        user-select: none;
    }

    #account {
        position: absolute;
        top: 15px;
        right: 15px;
        background-color: #733CFF;
        border-radius: 6px;
        padding: 10px;
    }

    #account:hover {
        cursor: pointer;
        user-select: none;
    }

</style>

import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import axios from "axios"

const routes = [
    {
        path: '/',
        component: () => import('../views/HomeView.vue')
    },
    {
        path: '/login',
        component: () => import('../views/LoginView.vue')
    },
    {
        path: '/setup/:id',
        component: () => import('../views/SetupView.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// Global navigation guard
router.beforeEach(async (to, from, next) => {
    window.scrollTo(0, 0)
    if (to.meta.requiresAccountData) {
        if(await store.dispatch('GET_ACCOUNT')) {
            next()
        } else {
            const token = localStorage.getItem(`token`)
            if (token) {
                await axios.post(`${this.$store.state.apiUrl}/fandle/auth`, {token: token})
                .then((res) => {
                    store.commit(`SET_ACCOUNT`, res.data.account)
                    next()
                })
                .catch((err) => {
                    console.error(err)
                    next(`/login`)
                })
            } else {
                next(`/login`)
            }
        }
    } else {
        next()
    }
})

export default router
import { createStore } from 'vuex'

export default createStore({
    state: {
        apiUrl: `https://guess-api-live-52f28b1eaf66.herokuapp.com`, // https://guess-api-live-52f28b1eaf66.herokuapp.com
        Account: false,
    },
    actions: {
        GET_ACCOUNT(state) {
            return state.ArtistData
        }
    },
    mutations: {
        SET_ACCOUNT(state, account) {
            state.Account = account
        },
        CLEAR_ACCOUNT(state) {
            state.Account = null
        },
    },
})

<template>
    
    <div id="expanded" v-if="expanded">

        <div id="returnContainer">
            <font-awesome-icon v-if="!expandedProfile" icon="fa-solid fa-arrow-left" id="expand" size="lg" @click="$emit(`ToggleExpand`)" />
        </div>

        <h3>Fandle Studio</h3>
        <AppExpandContent title="Home" icon="fa-solid fa-home" link="/" @OpenPage="$emit(`ToggleExpand`)" />
        <AppExpandContent title="Guess Queue" icon="fa-solid fa-home" :reverse=true link="/queue/guess" @OpenPage="$emit(`ToggleExpand`)" />
        <AppExpandContent title="Nine Queue" icon="fa-solid fa-home" link="/queue/nine" @OpenPage="$emit(`ToggleExpand`)" />
        
        <h3>Fandle</h3>
        <AppExpandContent title="Fandle" icon="fa-solid fa-home" link="https://fandle.io/" @OpenPage="$emit(`ToggleExpand`)" />

        <div id="end" />

    </div>
    
</template>

<script>

    import AppExpandContent from './AppExpandContent.vue'

    export default {
        props: [`expanded`],
        components: {
            AppExpandContent
        },
        methods: {
            OpenTab(page) {
                window.open(page, `_blank`)
            },
            LoadPage(page) {
                this.$router.push(page)
                this.$emit(`ToggleExpand`)
            },
        },
    }
  
</script>

<style scoped>

    #end {
        margin-bottom: 200px;
    }

    h3 {
        width: 90%;
        margin: 10px;
        margin-bottom: 5px;
        margin-top: 20px;
        text-align: left;
    }

    #returnContainer {
        padding: 35px 0px;
    }

    #expanded {
        height: 100%;
        padding-bottom: 400px;
        position: fixed;
        width: 25%;
        min-width: 350px;
        padding: 0px 10px;
        top: 0px;
        display: flex;
        flex-direction: column;
        background-color: #15151d;
        z-index: 99;
        overflow-y: auto;
    }

    #expanded::-webkit-scrollbar {
        width: 0px; 
    }

    #expanded a {
        padding: 5px 25px;
        color: white;
        text-decoration: none;
        text-align: left;
    }

    #expand {
        position: absolute;
        top: 15px;
        left: 15px;
        background-color: #733CFF;
        border-radius: 6px;
        padding: 10px;
    }

    #expand:hover {
        cursor: pointer;
        user-select: none;
    }

    @media screen and (max-width: 600px) {
        #expanded {
            width: 100%;
        }
    }

</style>

<template>
    
    <AppNav :expanded="expanded" :expandedProfile="expandedProfile" @ToggleExpand="expanded = !expanded" @ToggleProfileExpand="expandedProfile = !expandedProfile" />
    <div id="pageContainer">
        <router-view v-if="!(isMobile && (expanded || expandedProfile))" />
    </div>
    <AppExpanded :expanded="expanded" @ToggleExpand="expanded = !expanded" />

</template>

<script>

    import AppNav from "./components/App/AppNav.vue"
    import AppExpanded from "./components/App/AppExpanded.vue"

    export default {
        components: {
            AppNav,
            AppExpanded,
        },
        data() {
            return {
                expanded: false,
            }
        },
        methods: {
            CollapseExpandeds() {
            }
        },
    }
  
</script>

<style>

    @import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
  
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

    body {
        background-color: #0F0F14;
        font-family: 'Varela Round', sans-serif !important;
        font-family: 'Montserrat', sans-serif;
    }

    #app {
        text-align: center;
        color: white;
    }

    .icon {
        margin-bottom: 5px;
    }

    #pageContainer {
        width: 100%;
        min-height: 100vh;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }
    
    #editContainer {
        width: 80%;
        max-width: 350px;
        margin: auto;
        margin-top: 30px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
    }

    #editContainer input {
        padding: 5px;
        margin-bottom: 15px;
        font-size: 20px;
        color: white;
        text-align: left;
        background-color: #09090c;
        border-radius: 5px;
        outline: none;
    }

    #editContainer select {
        padding: 5px;
        margin-bottom: 15px;
        font-size: 20px;
        color: white;
        text-align: left;
        background-color: #09090c;
        border-radius: 5px;
        outline: none;
    }

    #editContainer h3 {
        margin-bottom: 5px;
        text-align: left;
    }

    #editButton {
        width: 100%;
        height: 5vh;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 20px;
        display: flex;
        color: #733CFF;
        border: 1px solid #733CFF;
        border-radius: 5px;
    }

    #editButton:hover {
        cursor: pointer;
        background: #733cff15;
    }

    #editButton p {
        margin: auto;
        font-weight: bold;
    }

    #cancelButton {
        width: 100%;
        height: 5vh;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        color: #da3c3c;
        border: 1px solid #da3c3c;
        border-radius: 5px;
    }

    #cancelButton:hover {
        cursor: pointer;
        background: #da3c3c21;
    }

    #cancelButton p {
        margin: auto;
        font-weight: bold;
    }

    #error {
        padding: 5px;
        margin-top: 20px;
        border-radius: 5px;
        background-color: #743117;
    }

    a {
        text-decoration: none;
        font-weight: bold;
        color: white;
        cursor: pointer;
    }

    .smallprint {
        color: gray;
    }

    #disclaimer {
        padding: 5px;
        margin-top: 40px;
        border-radius: 5px;
        background-color: #746a17;
    }

    #watermark {
        width: 570px;
        margin: auto;
        margin-top: -430px;
        font-size: 22px;
        z-index: 9999;
        color: rgba(255, 255, 255, 0.377);
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
    }

    .red {
        color: #da3c3c;
    }
    
    #controlsContainer {
        margin: auto;
        margin-bottom: 100px;
        padding: 20px 50px;
        background-color: #09090c;
        border-radius: 8px;
    }

    #startButton {
        margin: auto;
        width: 50%;
        margin-top: 20px;
        margin-bottom: 20px;
        outline: 1px solid rgb(19, 104, 64);
        padding: 10px;
        display: flex;
    }

    #startButton p {
        margin: auto;
    }


</style>
